body#elliottemple
  div#store
    h2
      color: #2F2F2F
      font-size: 1.3rem
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif
    h2 a:hover
      text-decoration: underline
  div#home
    h3
      margin-top: 0px
      padding-top: 0px
      
      a
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif
        color: #106810
        font-size: 1.4rem
        text-decoration: underline



#reasonandmorality ul
  margin-bottom: 0px

#reasonandmorality ul
  font-size: 1.4rem

#reasonandmorality ul ul
  font-size: 1.2rem

#reasonandmorality ul ul ul
  font-size: 1.1rem

#reasonandmorality ul ul ul ul
  font-size: 1rem



b.john
  color: #A81717 !important